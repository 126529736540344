<template>
    <div>
        <Header :instanceInfo="instanceInfo" />
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="box" style="margin-top:10px; border: none!important;">
                        <!--Main Start-->
                        <div class="container">
                            <div class="col-md-12">
                                <h3>Congratulations !!!</h3>
                                <p class="alert alert-success">Your account was created.<br />
                                    Email with activation link was sent to the email address provided in the signup form.<br />
                                    Click link in the email message to activate your account.<br />
                                    Once your account is active you can login and have fun.
                                </p>
                                <router-link to="/login" class="btn btn-default">
                                    Login
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs" />
    </div>
</template>

<script>
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';

export default {
    name: 'SignupSuccess',
    components: { Header, Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
};
</script>
